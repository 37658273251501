.brands-bg {
    display: flex;
    flex-direction: column;
    background-color: #0a0a0a;
    color: white;
    padding-top: 80px;
}

.brands-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.brands-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 65%;
    align-items: center;
    margin-top: 15px;
}

.brands-img {
    width: 250px;
    height: auto;
    transition: all 0.3s ease;
}

.brands-img:hover {
    transform: scale(1.1);
}

.lf {
    height: 150px;
    width: auto;
}

@media only screen and (max-width: 1100px) {
    .brands-bg > h1 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .brands-row {
        flex-direction: column;
    }

    .brands-img {
        width: 150px;
        height: auto;
        margin-bottom: 15px;
    }
}