h1 {
    font-family: "Lulo Clean One Bold", sans-serif;
    font-size: 60px;
    margin: 0;
}

h2 {
    font-family: "Lulo Clean One Bold", sans-serif;
    font-size: 25px;
    margin: 0;
}

.hero {
    padding: 0;
    display: block;
    height: 120vh;
}

#hero-video,
.videoGif {
    position: fixed;
    left: 0;
    height: 100%;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    object-fit: cover;
}

.hero-text-box {
    position: absolute;
    bottom: 40vh;
    text-align: left;
    margin-left: 80px;
}

.glowingText {
    font-family: "Lulo Clean One", sans-serif;
    text-shadow: 0 0 10px #fff;
    color: white;
}

.btn-banner {
    margin-top: 40px;
    background: none;
    border: none;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.btn-banner:hover {
    color: #fff;
    background: rgba(255, 255, 255, 0.63);
    border-radius: 5px;
    transform: scale(1.1);
}

.btn-banner > img {
    filter: invert(100%) sepia(37%) saturate(0%) hue-rotate(305deg) brightness(106%) contrast(107%);
    width: 25px;
    height: 25px;
    vertical-align: bottom;
    margin-left: 5px;
}

@media only screen and (max-width: 1100px) {
    .hero {
        height: 100vh;
    }

    #hero-video,
    .videoGif{
        width: 100vw;
        min-width: 100vw;
    }

    .hero-text-box {
        text-align: center;
        margin: 0 auto;
        top: 160px;
        width: 100%;
    }

    .hero-text-box h1 {
        font-size: 30px;
    }

    .hero-text-box h2 {
        font-size: 15px;
    }

    .btn-banner {
        font-size: 15px;
    }

}