html {
  scroll-behavior: smooth;
}

body {
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: Lulo Clean One;
  src: local(Lulo Clean One), url("Lulo-Clean-One.de9d5856.ttf") format("truetype");
}

@font-face {
  font-family: Lulo Clean One Bold;
  src: local(Lulo Clean One Bold), url("Lulo-Clean-One-Bold.4e6a61d9.ttf") format("truetype");
}

@font-face {
  font-family: Trend-Four;
  src: url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.eot");
  src: url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.woff") format("woff"), url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.svg#TrendSansW00-Four") format("svg");
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
}

.App-intro {
  font-size: small;
}

#navbar {
  width: 100%;
  height: 80px;
  background-color: #030303;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transition: all .3s;
  display: flex;
  position: fixed;
  top: 0;
}

.active, .activeLanguage {
  transition: all .3s;
  color: #787878 !important;
}

.navbar-left {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.navbar-left > a {
  color: #fff;
  padding: 0;
  font-size: 1.5em;
  font-weight: bold;
  text-decoration: none;
}

.navbar-center {
  text-align: center;
}

.navbar-center > a {
  color: #f2f2f2;
  padding: 10px;
  font-size: 14px;
  text-decoration: none;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.navbar-languages {
  position: absolute;
  right: 10px;
}

.navbar-languages > a {
  color: #fff;
  margin-right: 8px;
  padding: 0;
  font-weight: normal;
  text-decoration: none;
}

.navbar-right {
  height: 100%;
  align-items: center;
  display: flex;
}

.socialmedia {
  cursor: pointer;
  margin-right: 5px;
  transition: all .3s ease-in-out;
}

.socialmedia:hover {
  transform: scale(1.3);
}

#navbar a:hover {
  color: #787878;
}

.text {
  margin-top: 50px;
  padding: 15px 15px 2500px;
  font-size: 30px;
}

.logoNavbar {
  width: auto;
  height: 150px;
  margin-top: 10px;
}

@media only screen and (max-width: 1100px) {
  #navbar {
    justify-content: space-around;
  }

  .navbar-languages {
    margin-right: 20px;
    position: absolute;
    right: 0;
  }

  .navbar-center, .navbar-right {
    display: none;
  }
}

h1 {
  margin: 0;
  font-family: Lulo Clean One Bold, sans-serif;
  font-size: 60px;
}

h2 {
  margin: 0;
  font-family: Lulo Clean One Bold, sans-serif;
  font-size: 25px;
}

.hero {
  height: 120vh;
  padding: 0;
  display: block;
}

#hero-video, .videoGif {
  height: 100%;
  width: auto;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  object-fit: cover;
  position: fixed;
  left: 0;
}

.hero-text-box {
  text-align: left;
  margin-left: 80px;
  position: absolute;
  bottom: 40vh;
}

.glowingText {
  text-shadow: 0 0 10px #fff;
  color: #fff;
  font-family: Lulo Clean One, sans-serif;
}

.btn-banner {
  cursor: pointer;
  background: none;
  border: none;
  margin-top: 40px;
  font-size: 20px;
  transition: all .3s ease-in-out;
}

.btn-banner:hover {
  color: #fff;
  background: #ffffffa1;
  border-radius: 5px;
  transform: scale(1.1);
}

.btn-banner > img {
  filter: invert() sepia(37%) saturate(0%) hue-rotate(305deg) brightness(106%) contrast(107%);
  width: 25px;
  height: 25px;
  vertical-align: bottom;
  margin-left: 5px;
}

@media only screen and (max-width: 1100px) {
  .hero {
    height: 100vh;
  }

  #hero-video, .videoGif {
    width: 100vw;
    min-width: 100vw;
  }

  .hero-text-box {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    top: 160px;
  }

  .hero-text-box h1 {
    font-size: 30px;
  }

  .hero-text-box h2, .btn-banner {
    font-size: 15px;
  }
}

.fw-content {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding-top: 40px;
  display: flex;
}

.fw-header {
  width: 70%;
  border-bottom: 1px solid #d3d3d3;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  margin-left: 15%;
  padding-bottom: 20px;
  display: flex;
}

.fw-title {
  text-align: left;
  color: #fff;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  overflow: hidden;
}

.fw-oneShowcase > .fw-title {
  text-align: center;
}

.fw-showcase {
  width: 200%;
  flex-direction: row;
  margin-top: 20px;
  transition: all 1s ease-in-out;
  display: flex;
  position: relative;
  left: 0;
}

.first-videos, .second-videos {
  width: 97vw;
  flex-direction: row;
  justify-content: space-evenly;
  display: flex;
}

.fw-oneShowcase {
  width: 40%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  transition: all .3s ease-in-out;
  display: flex;
}

.fw-oneShowcase > img {
  width: 40vw;
  height: auto;
  margin: 0 0 10px;
}

.fw-oneShowcase:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.chevron-container {
  position: absolute;
  top: 40%;
  left: 48%;
}

.chevron {
  width: 64px;
  height: 64px;
  cursor: pointer;
  filter: invert() sepia(0%) saturate(7479%) hue-rotate(86deg) brightness() contrast(96%);
  transition: all .5s ease-in-out;
}

.chevron:hover {
  transform: scale(1.1);
}

@media only screen and (max-width: 1100px) {
  .fw-header {
    width: 90%;
    margin-left: 5%;
  }

  .fw-title {
    font-size: 16px;
  }

  .fw-oneShowcase {
    width: 90vw;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .first-videos {
    width: 100vw;
    height: 550px;
    flex-direction: column;
  }

  .second-videos, .chevron-container {
    display: none;
  }

  .fw-oneShowcase > img {
    width: 50vw;
    height: auto;
    max-width: 380px;
    max-height: 213px;
  }
}

.brands-bg {
  color: #fff;
  background-color: #0a0a0a;
  flex-direction: column;
  padding-top: 80px;
  display: flex;
}

.brands-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.brands-row {
  width: 65%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 15px;
  display: flex;
}

.brands-img {
  width: 250px;
  height: auto;
  transition: all .3s;
}

.brands-img:hover {
  transform: scale(1.1);
}

.lf {
  height: 150px;
  width: auto;
}

@media only screen and (max-width: 1100px) {
  .brands-bg > h1 {
    margin-bottom: 20px;
    font-size: 40px;
  }

  .brands-row {
    flex-direction: column;
  }

  .brands-img {
    width: 150px;
    height: auto;
    margin-bottom: 15px;
  }
}

.presentation-container {
  color: #fff;
  padding-top: 40px;
}

.parallax-presentation {
  height: 650px;
}

.presentation-header {
  width: 80%;
  border-bottom: 1px solid #d3d3d3;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-left: 10%;
  padding-bottom: 20px;
  display: flex;
}

.presentation-content {
  width: 50%;
  height: fit-content;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  margin-top: 30px;
  display: flex;
}

.presentation-title {
  text-shadow: 0 0 3px #fff;
  letter-spacing: 8px;
  font-family: Trend-Four, sans-serif;
}

.presentation-sub {
  letter-spacing: 4px;
  margin-left: 10px;
  font-size: 16px;
}

.presentation-content > p {
  letter-spacing: 3px;
  text-align: justify;
  margin-left: 10px;
  font-size: 17px;
}

.presentation-wrapper {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.presentation-photo > img {
  width: auto;
  height: 400px;
  margin-left: 20px;
}

@media only screen and (max-width: 1100px) {
  .parallax-presentation {
    height: 750px;
  }

  .presentation-photo {
    display: none;
  }

  .presentation-content {
    width: 90%;
  }

  .presentation-title {
    font-size: 20px;
  }

  .presentation-sub {
    display: none;
  }
}

.footer {
  width: 100%;
  height: 150px;
  background-color: #000;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-footer {
  color: #fff;
  cursor: pointer;
  background: none;
  border: 2px solid #fff;
  padding: 15px 50px;
  font-family: Lulo Clean One, sans-serif;
  transition: all .3s ease-in-out;
}

.btn-footer:hover {
  color: #000;
  background: #fff;
  transform: scale(1.1);
}

.form-container {
  width: 80%;
  margin-top: 30px;
}

.form-group, .form-row {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
  display: flex;
}

label {
  margin-bottom: 5px;
}

input, textarea {
  color: #fff;
  background: #353a51;
  border: none;
  border-bottom: 2px solid #b1f6ff;
  margin-bottom: 5px;
  padding: 10px;
}

input::placeholder, textarea::placeholder {
  color: #848daa;
}

.submitBtn {
  color: #848daa;
  width: 100px;
  cursor: pointer;
  background: #353a51;
  border: none;
  padding: 10px;
  font-weight: bold;
  transition: all .2s ease-in-out;
}

.submitBtn:hover {
  color: #b1f6ff;
  transform: scale(1.1);
}

.Modal {
  color: #fff;
  position: absolute;
  inset: 40px;
}

.Overlay {
  background: #000c;
  position: fixed;
  inset: 0;
}

.closeBtn {
  color: #fff;
  cursor: pointer;
  background: none;
  font-size: 20px;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 10px;
  right: 10px;
}

.closeBtn:hover {
  color: #e3e3e3;
  transform: scale(1.5);
}

.contact-modal-body {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.spinner-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.spinner {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.8c22ea01.css.map */
