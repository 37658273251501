.fw-content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    padding-top: 40px;
}

.fw-header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    width: 70%;
    margin-left: 15%;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgrey;
}
.fw-title {
    font-size: 18px;
    text-align: left;
    color: white;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.fw-oneShowcase > .fw-title {
    text-align: center;
}

/* Featured Work Showcasing */

.fw-showcase {
    display: flex;
    position: relative;
    flex-direction: row;
    margin-top: 20px;
    width: 200%;
    left: 0;
    transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
}

.first-videos {
    display: flex;
    flex-direction: row;
    width: 97vw;
    justify-content: space-evenly;
}

.second-videos {
    display: flex;
    flex-direction: row;
    width: 97vw;
    justify-content: space-evenly;
}

.fw-oneShowcase {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 40%;
    transition: all 0.3s ease-in-out;
}

.fw-oneShowcase>img {
    width: 40vw;
    height: auto;
    margin: 0 0 10px;
}

.fw-oneShowcase:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.chevron-container {
    position: absolute;
    top: 40%;
    left: 48%;
}

.chevron {
    width: 64px;
    height: 64px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    filter: invert(100%) sepia(0%) saturate(7479%) hue-rotate(86deg) brightness(100%) contrast(96%);
}

.chevron:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 1100px) {
    .fw-header {
        width: 90%;
        margin-left: 5%;
    }

    .fw-title {
        font-size: 16px;
    }

    .fw-oneShowcase {
        width: 90vw;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .first-videos {
        flex-direction: column;
        width: 100vw;
        height: 550px;
    }

    .second-videos, .chevron-container {
        display: none;
    }

    .fw-oneShowcase > img {
        width: 50vw;
        height: auto;
        max-width: 380px;
        max-height: 213px;
    }
}