.presentation-container {
    padding-top: 40px;
    color: white;
}

.parallax-presentation {
    height: 650px;
}

.presentation-header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    width: 80%;
    margin-left: 10%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid lightgrey;
}

.presentation-content {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    width: 50%;
    height: fit-content;
    margin-top: 30px;
}

.presentation-title {
    font-family: "Trend-Four", sans-serif;
    text-shadow: #ffffff 0 0 3px;
    letter-spacing: 8px;
}

.presentation-sub {
    font-size: 16px;
    letter-spacing: 4px;
    margin-left: 10px;
}

.presentation-content>p {
    font-size: 17px;
    letter-spacing: 3px;
    text-align: justify;
    margin-left: 10px;
}

.presentation-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.presentation-photo>img {
    width: auto;
    height: 400px;
    margin-left: 20px;
}

@media only screen and (max-width: 1100px) {
    .parallax-presentation {
        height: 750px;
    }

    .presentation-photo {
        display: none;
    }

    .presentation-content {
        width: 90%;
    }

    .presentation-title {
        font-size: 20px;
    }

    .presentation-sub {
        display: none;
    }

}