html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: black;
}

@font-face {
    font-family: "Lulo Clean One";
    src: local("Lulo Clean One"), url("./Assets/Fonts/Lulo-Clean-One.ttf") format("truetype");
}

@font-face {
    font-family: "Lulo Clean One Bold";
    src: local("Lulo Clean One Bold"), url("./Assets/Fonts/Lulo-Clean-One-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Trend-Four";
    src: url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.eot");
    src: url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.woff") format("woff"), url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/33ca94f43a53fc634323a6fe191ffd64.svg#TrendSansW00-Four") format("svg");
}
