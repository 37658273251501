#navbar {
    background-color: rgb(3, 3, 3);
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    transition: 0.3s;
}

.active {
    color: rgb(120, 120, 120)!important;
    transition: 0.3s;
}

.activeLanguage {
    color: rgb(120, 120, 120)!important;
    transition: 0.3s;
}

.navbar-left {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-left>a {
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    text-decoration: none;
    padding: 0;
}

.navbar-center {
    text-align: center;
}

.navbar-center>a {
    display: inline-block;
    color: #f2f2f2;
    padding: 10px;
    text-decoration: none;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
}

.navbar-languages {
    position: absolute;
    right: 10px;
}

.navbar-languages > a {
    color: white;
    font-weight: normal;
    text-decoration: none;
    padding: 0;
    margin-right: 8px;
}

.navbar-right {
    height: 100%;
    display: flex;
    align-items: center;
}

.socialmedia {
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.socialmedia:hover {
    transform: scale(1.3);
}

#navbar a:hover {
    color: rgb(120, 120, 120);
}

.text {
    padding: 15px 15px 2500px;
    font-size: 30px;
    margin-top: 50px;
}

.logoNavbar {
    width: auto;
    height: 150px;
    margin-top: 10px;
}

@media only screen and (max-width: 1100px) {
    #navbar {
        justify-content: space-around;
    }

    .navbar-languages {
        margin-right: 20px;
        position: absolute;
        right: 0;
    }

    .navbar-center {
        display: none;
    }

    .navbar-right {
        display: none;
    }
}