.Modal {
    position: absolute;
    color: white;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
}

.closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: white;
    background: none;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.closeBtn:hover {
    color: #e3e3e3;
    transform: scale(1.5);
}

.contact-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}